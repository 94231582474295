export const REGULARISATION_DATA = {
    date_debut_exercice: null,
    date_cloture_exercice: null,
    date_facture: null,
    date_livraison: null,
    periode_debut_prestation: null,
    periode_fin_prestation: null,
    cloture_exercice_precedent: null
}

export const REGULARISATION_MODEL = {
    valide_decision: [],
    rejected_decision: [],
    unknown_decision: []
}