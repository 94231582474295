import { createSlice } from '@reduxjs/toolkit'

export const agendaSlice = createSlice({
    name: 'agenda',
    initialState: {
        responsables: [0],
        tache_libre: true,
        tache_legale: true,
        tache_faite: true,
        event_edited: null,
        dossier: 0,
        exercice: 0,
        tache_id: 0, 
        maj: false
    },
    reducers: {
        handleResponsablesChange: (state, action) => {
            state.responsables = action.payload
        },
        handleTacheLibreChange: (state, action) => {
            state.tache_libre = action.payload
        },
        handleTacheLegaleChange: (state, action) => {
            state.tache_legale = action.payload
        },
        handleTacheFaiteChange: (state, action) => {
            state.tache_faite = action.payload
        },
        handleEventEditedChange: (state, action) => {
            state.event_edited = action.payload
        },
        handleAgendaExerciceChange: (state, action) => {
            state.exercice = action.payload
            state.dossier = 0
        },
        handleAgendaDossierChange: (state, action) => {
            state.dossier = action.payload
        },
        handleTacheIdChange: (state, action) => {
            state.tache_id = action.payload
        },
        handleMajClick: (state, action) => {
            state.maj = action.payload
        }
    }
})

export const {
    handleResponsablesChange, 
    handleTacheLibreChange, 
    handleTacheLegaleChange, 
    handleTacheFaiteChange, 
    handleEventEditedChange, 
    handleAgendaExerciceChange, 
    handleAgendaDossierChange,
    handleTacheIdChange,
    handleMajClick
} = agendaSlice.actions

export default agendaSlice.reducer