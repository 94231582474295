import { French } from "flatpickr/dist/l10n/fr.js"
import { createMRTColumnHelper } from "material-react-table"
import moment from "moment"
import { FILE_CONSTANT } from "../../configs/constant"
import { REGULARISATION_MODEL } from "../../constants/regularisation.constant"
export const loadingParams = 'isLoading'

export const pickerOptions = {
    locale: {
      ...French,
      months: {
        ...French.months
      }
    },
    dateFormat: "d/m/Y",
    mode: "range"
  }

export const getExercices = (nbr, start) => {
    nbr = typeof nbr !== 'undefined' ? nbr : 7
    start = typeof start !== 'undefined' ? start : -5
    const yearNow = new Date().getFullYear()

    const exos = []
    for (let i = 0; i < nbr; i++) {
        exos.push(yearNow + start + i)
    }

    return exos
}

export const moment_fr = {
  months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact : true,
  weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact : true,
  longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D MMMM YYYY',
      LLL : 'D MMMM YYYY HH:mm',
      LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  calendar : {
      sameDay : '[Aujourd’hui à] LT',
      nextDay : '[Demain à] LT',
      nextWeek : 'dddd [à] LT',
      lastDay : '[Hier à] LT',
      lastWeek : 'dddd [dernier à] LT',
      sameElse : 'L'
  },
  relativeTime : {
      future : 'dans %s',
      past : 'il y a %s',
      s : 'quelques secondes',
      m : 'une minute',
      mm : '%d minutes',
      h : 'une heure',
      hh : '%d heures',
      d : 'un jour',
      dd : '%d jours',
      M : 'un mois',
      MM : '%d mois',
      y : 'un an',
      yy : '%d ans'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal (number) {
      return number + (number === 1 ? 'er' : 'e')
  },
  meridiemParse : /PD|MD/,
  isPM (input) {
      return input.charAt(0) === 'M'
  },
  meridiem (hours) {
      return hours < 12 ? 'PD' : 'MD'
  },
  week : {
      dow : 1,
      doy : 4
  }
}
/**
 * 
 * @param {string} label 
 * @param {string} value 
 * @returns {{
 * label: string,
 * value: string
 * }}
 */
export const selectOptionsParser = (label, value) => ({
    label,
    value
})


/**
 * 
 * @param {number} len 
 * @returns {boolean}
 */
Array.prototype.isLenEqual = function (len) {
    return this.length === len
}

Array.prototype.isLenGreatThan = function (len, strict) {
    return strict ? this.length > len : this.length >= len 
}

Array.prototype.isLenLessThan = function (len, strict) {
    return strict ? this.length < len : this.length <= len
}


export const columnHelper = createMRTColumnHelper()


export const typeSaise = {
    su: {
        value: 0,
        libelle: 'SU',
        color: 'warning'
    },
    compta: {
        value: 1,
        libelle: 'Compta',
        color: 'danger'
    }
}

export const dateClotureDossier = (moisCloture, exercice) => {
    let mois = moisCloture + 1

    if (mois > 12) {
        mois -= 12
        exercice++
    }

    mois = mois.toString().padStart(2, '0') 
    let dateCloture = moment(`${exercice}-${mois}-01`)
    dateCloture = dateCloture.subtract(1, 'days')
    return dateCloture
}

export const capitalizeFirstLetter = (string) => {
    if (string) {
        return string[0].toUpperCase() + string.slice(1)
    }
    return string
}

export const getFileInfo = (extension) => {
    let fileKey = 'default_file'

    const filesKey = Object.keys(FILE_CONSTANT)
    for (const key in filesKey) {
        const elementKey = filesKey[key]
        const extenstionFound = FILE_CONSTANT[elementKey]?.extension.find(fileExtension => {
            return fileExtension.toString().toLowerCase().trim() === extension.toString().toLowerCase().trim()
        })

        if (extenstionFound) {
            fileKey = elementKey
            break
        }
    }

    return FILE_CONSTANT[fileKey]
}

export const testCondition = (expression, trigger_error = false) => {
    try {
        // Ensure that we only allow safe operators to avoid security risks
        if (expression) {
            expression = expression.toString()
            return Function(`return (${expression})`)()
        } else {
            return false
        }
        
        // const safeExpression = expression.replaceall(/[^0-9\-+*/<>=()." '\n]/g, '')
        // return Function(`return (${expression})`)()
    } catch (error) {
        if (trigger_error) {
            throw error
        }
        return false
    }
}

const renderValueByType = (value, type = 0, defautltValue = null) => {
    if (type === 2) {
        const date = moment(value)
        if (date.isValid()) {
            return date.format('YYYY-MM-DD')
        }
        return defautltValue
    }

    return value
}

const renderFormuleResult = (data, formule, parameters) => {
    let formuleResult = ''
    const formuleElements = formule.split(' ')
    for (const elementKey of formuleElements) {
        let element = elementKey
        if (element.includes('#')) {
            const field = parameters.find(parameter => parameter.code === element.replaceAll('#', ''))
            if (field?.libelle) {
                element = data[field.libelle] ? renderValueByType(data[field.libelle], field.type, null) : null
            } else {
                element = null
            }

            if (element) {
                element = `'${element}'`
            }
        }
        if (element === null) {
            return 'true === false'
        }
        formuleResult = `${formuleResult} ${element} `
    }

    return formuleResult
}

const getFormuleRelations = (relation, formules) => {
    let formule = ''
    if (relation.formule) {
        const allFormules = relation.formule.split(' ')
        allFormules.forEach(parameters => {
            if (parameters.includes('#')) {
                const regularisationFormuleDecision = formules.find(formuleElement => formuleElement?.regularisation_formule_decision?.id.toString() === parameters.replaceAll('#', ''))
                if (regularisationFormuleDecision) {
                    parameters = `(${regularisationFormuleDecision.formule})`
                }
            }
            formule = `${formule} ${parameters} `
        })
    }

    return formule
}

const getFormuleOfFormuleDecision = (regularisationFormuleDecision, fields, parametreFormule) => {
    let finalFormule = ''
    let formuleDecision = regularisationFormuleDecision.formule_decision ? regularisationFormuleDecision.formule_decision.formule : regularisationFormuleDecision.formule
    if (formuleDecision) {
        formuleDecision = `${formuleDecision}`
        if (regularisationFormuleDecision?.regularisation_formule_params?.length > 0) {
            regularisationFormuleDecision.regularisation_formule_params.forEach(regularisationFormuleParams => {
                const allParams = regularisationFormuleParams.formule ? regularisationFormuleParams.formule.split(' ') : []
                allParams.forEach(parameters => {
                    if (parameters.includes('#')) {
                        parameters = formuleDecision.replaceAll(regularisationFormuleParams.params, parameters)
                    } else if (parameters.includes('$')) {
                        parameters = formuleDecision.replaceAll(regularisationFormuleParams.params, `'${parameters.replaceAll('$', '')}'`)
                    }
                    
                    parameters = renderFormuleResult(fields, parameters, parametreFormule)

                    finalFormule = `${finalFormule} ${parameters} `
                })
            })
        }
    }
    return finalFormule
}

export const regularisationDecisionHandler = (fields = {}, regularisationParameters = {
    parametreFormule: [],
    regularisationDecision: []
}) => {
    const decisionsApplied = {...REGULARISATION_MODEL,
        valide_decision: [],
        rejected_decision: [],
        unknown_decision: []
    }
        
    regularisationParameters.regularisationDecision.forEach(regularisationDecision => {
        let regularisationDecisionFormule = ''
        if (regularisationDecision?.regularisation_formule_decision?.length > 0) {
            const regularisationDecisionFormules = []
            regularisationDecision.regularisation_formule_decision.forEach(regularisationFormuleDecision => {
                const formule = getFormuleOfFormuleDecision(regularisationFormuleDecision, fields, regularisationParameters.parametreFormule)
                if (formule) {
                    regularisationDecisionFormules.push({
                        regularisation_formule_decision: regularisationFormuleDecision,
                        // formule: renderFormuleResult(fields, formule, regularisationParameters.parametreFormule)
                        formule
                    })
                }
            })

            regularisationDecision.relation_regularisation_formule_decision.forEach(relationRegularisationFormuleDecision => {
                const formuleRelation = getFormuleRelations(relationRegularisationFormuleDecision, regularisationDecisionFormules)
                regularisationDecisionFormule = `${regularisationDecisionFormule} ${formuleRelation}`
            })

            try {
                const conditionApplied = testCondition(regularisationDecisionFormule)
                if (conditionApplied) {
                    decisionsApplied.valide_decision.push({
                        regularisation_decision: regularisationDecision,
                        result_decision: regularisationDecisionFormule
                    })
                } else {
                    decisionsApplied.rejected_decision.push({
                        regularisation_decision: regularisationDecision,
                        result_decision: regularisationDecisionFormule
                    })
                }
            } catch (error) {
                decisionsApplied.unknown_decision.push({
                    regularisation_decision: regularisationDecision,
                    result_decision: regularisationDecisionFormule
                })
            }
        }
    })

    return decisionsApplied
}