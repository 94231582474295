// ** Logo
import logo from "@src/assets/images/logo/logo.png"
import { useLocation } from "react-router-dom"
const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
const SpinnerComponent = () => {
  const query = useQuery()
  const value = query.get("show-logo")

  return (
    <div className="fallback-spinner app-loader">
      {
        !value &&  <img className="fallback-logo" src={logo} alt="logo" />
      }
    
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
