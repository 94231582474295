// ** Render Select Data
const getSelectLabel = (optionObject, label, separator = '-') => {
    let selectLabel = ''
    if (Array.isArray(label)) {
        selectLabel = ''
        label.forEach((property, index) => {
            selectLabel = `${selectLabel} ${optionObject[property] ?? ''}`
            if (index < label.length - 1) {
                selectLabel = `${selectLabel} ${separator}`
            }
        })
    } else {
        selectLabel = optionObject[label]
    }

    if (typeof selectLabel === 'string') {
        selectLabel = selectLabel.trim()
    }
    return selectLabel ?? ''
}

export {
    getSelectLabel
}