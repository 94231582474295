import { createSlice } from '@reduxjs/toolkit'

export const filtreSlice = createSlice({
    name: 'filtre',
    initialState: {
        exercice: 0,
        client: 0,
        site: 0,
        dossier: 0,
        exercice: 0,
        banque: 0,
        mois: [],
        modeSaisie: null,
        banqueCompte: null,
        souscategorie: null,
        datescan: null,
        journal_dossier: 0,
        periodescan : {start: null, end: null},
        status: null,
        listeDossiers: [],
        typeDateTdi: 0,
        typeFilterImageTdi: 0,
        typeVehicule: 0,
        exercices: [],
        dossiers: [],
        budget_dossier: 0
    },
    reducers: {
        handleChangeStatus: (state, action) => {
            state.status = action.status
        },
        handleExerciceChange: (state, action) => {
            state.exercice = action.payload
            state.exercices = []
            state.site = 0
            state.dossier = 0
            state.banque = 0
            state.banqueCompte = null
            state.modeSaisie = null
            state.mois = []
            state.souscategorie = null
            state.datescan = null
            state.journal_dossier = 0
            state.periodescan = {start: null, end: null}
            state.dossiers = []
            state.budget_dossier = 0
        },
        handleClientChange: (state, action) => {
            state.client = action.payload
            state.site = 0
            state.dossier = 0
            state.banque = 0
            state.banqueCompte = null
            state.modeSaisie = null
            state.mois = []
            state.souscategorie = null
            state.datescan = null
            state.journal_dossier = 0
            state.periodescan = {start: null, end: null}
            state.dossiers = []
            state.budget_dossier = 0
        },
        handleSiteChange: (state, action) => {
            state.site = action.payload
            state.dossier = 0
            state.banque = 0
            state.banqueCompte = null
            state.modeSaisie = null
            state.mois = []
            state.souscategorie = null
            state.datescan = null
            state.journal_dossier = 0
            state.periodescan = {start: null, end: null}
            state.dossiers = []
            state.budget_dossier = 0
        },
        handleDossierChange: (state, action) => {
            state.dossier = action.payload
            state.banque = 0
            state.banqueCompte = null
            state.modeSaisie = null
            state.mois = []
            state.souscategorie = null
            state.datescan = null
            state.journal_dossier = 0
            state.periodescan = {start: null, end: null}
            state.budget_dossier = 0
        },
        handleBanqueChange: (state, action) => {
            state.banque = action.payload
            state.banqueCompte = null
            state.modeSaisie = null
        },
        handleBanqueCompteChange: (state, action) => {
            state.banqueCompte = action.payload
        },
        handleMoisChange: (state, action) => {
            state.mois = action.payload
        },
        handleModeSaisieChange: (state, action) => {
            state.modeSaisie = action.payload
        },
        handlePeriodeScan: (state, action) => {
            state.periodescan = action.payload
        },
        handleSouscategorieChange: (state, action) => {
            state.souscategorie = action.payload
        },
        handleDatescanChange: (state, action) => {
            state.datescan = action.payload
        },
        handleJournalDossierChange: (state, action) => {
            state.journal_dossier = action.payload
        },
        handleListeDossiers: (state, action) => {
            state.listeDossiers = action.payload
        }, 
        handleSelectChangeTypeDateTdi: (state, action) => {
            state.typeDateTdi = action.payload
        },        
        handleRadioChangeTypeFilterImageTdi : (state, action) => {
            state.typeFilterImageTdi = action.payload
        },
        handleTypeVehiculeChange: (state, action) => {
            state.typeVehicule = action.payload
        },
        handleExercicesChange: (state, action) => {
            state.exercices = action.payload
            state.exercice = 0
            state.site = 0
            state.dossier = 0
            state.banque = 0
            state.banqueCompte = null
            state.modeSaisie = null
            state.mois = []
            state.souscategorie = null
            state.datescan = null
            state.journal_dossier = 0
            state.periodescan = {start: null, end: null}
        },
        handleDossiersChange: (state, action) => {
            state.dossiers = action.payload
            state.dossier = 0
        },
        handleBudgetDossierChange: (state, action) => {
            state.budget_dossier = action.payload
        }
    }
})

export const {
    handleExerciceChange,
    handleClientChange,
    handleSiteChange,
    handleDossierChange,
    handleBanqueChange,
    handleBanqueCompteChange,
    handleMoisChange,
    handleModeSaisieChange,
    handleSouscategorieChange,
    handleDatescanChange,
    handlePeriodeScan,
    handleJournalDossierChange,
    handleListeDossiers,
    handleSelectChangeTypeDateTdi,
    handleRadioChangeTypeFilterImageTdi,
    handleTypeVehiculeChange,
    handleExercicesChange,
    handleDossiersChange,
    handleBudgetDossierChange
//    handleSitesChange
} = filtreSlice.actions

export default filtreSlice.reducer
